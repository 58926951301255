import React from 'react';
import './About.css';
import MehmetCabbar from '../images/mehmetCabbar.png'

function About() {
    return (
        <div id='about' className='aboutContainer'>
            <img src={MehmetCabbar} className='aboutImage' alt='Mehmet Cabbar' />
            <h3 className='aboutTitle'>Experience History w/Contact</h3>
            <div className='aboutContent'>
                <p>
                    I’m from Istanbul, Turkey, and I love combining my passion for coding with long bike trips and camping in nature. I believe traveling fuels personal growth and often code while on the road. My passion lies in creating user-friendly solutions by empathizing with designers, users, and business needs. I thrive in collaborative environments, enjoy brainstorming, learning from team members, sharing knowledge, and helping others. My goal is to create international products, continuously embracing the spirit of teamwork and innovation.
                </p>
                <p>Email address: mhmtcbbr@gmail.com</p>

            </div>
        </div>
    )
}

export default About