import './Hello.css'
import React from 'react'

function Hello() {
    return (
        <div className='helloContainer'>
            <h1 className='helloTitle'>HELLO!  - I AM MEHMET</h1>
            <div className='helloText'>
                <p>
                    I'm a seasoned front-end developer with a background in graphic design. I like to combining user- friendly development with design principles. My ability is empathize with users and to create business solutions. I thrive in collaborative brainstorms, continuously learn from my peers, and actively contribute to building products. Let's embark on a journey of innovation and create remarkable experiences together!
                </p>
            </div>
            <div className='helloFollow'>
                Follow me:
                <a target='_blank' rel="noreferrer" href='https://github.com/mehmetcabbar' className='helloSocialIcon'>
                    <ion-icon name="logo-github"></ion-icon>
                </a>
                <a target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/mehmet-cabbar-87a884143/' className='helloSocialIcon'>
                    <ion-icon name="logo-linkedin"></ion-icon>
                </a>
                <a target='_blank' rel="noreferrer" href='https://www.instagram.com/aboutpersonalstuff/' className='helloSocialIcon'>
                    <ion-icon name="logo-instagram"></ion-icon>
                </a>
            </div>
        </div>
    )
}

export default Hello